// Importing Images
import sandeepjain1 from "../../assets/Sessions/SandeepJain/sandeepjain1.jpg";
import sandeepjain2 from "../../assets/Sessions/SandeepJain/sandeepjain2.jpg";
import sandeepjain3 from "../../assets/Sessions/SandeepJain/sandeepjain3.jpg";
import sandeepjain4 from "../../assets/Sessions/SandeepJain/sandeepjain4.jpg";
import sandeepjain5 from "../../assets/Sessions/SandeepJain/sandeepjain5.jpg";
import sandeepjain6 from "../../assets/Sessions/SandeepJain/sandeepjain6.jpg";

// Slider Data
const slides = [
  {
    id: 1,
    url: sandeepjain1,
    caption: "With Sandeep Jain, CSE AIML Department faculty, HOD, TPO, and student leaders from GFG Chapter and AIMSA, inspiring students at the seminar.",
  },
  {
    id: 2,
    url: sandeepjain2,
    caption: "Felicitated by Shitelkumar Ravandhale: Sandeep Jain, Visionary Founder of GeeksforGeeks",
  },
  {
    id: 3,
    url: sandeepjain3,
    caption: "GFG Chapter President addressing the seminar attendees with insights and inspiration",
  },
  {
    id: 4,
    url: sandeepjain4,
    caption: "Sandeep Jain: Inspiring Minds at GeeksforGeeks 🚀",
  },
  {
    id: 5,
    url: sandeepjain5,
    caption: "Sandeep Jain awarding the winners with pride with GeeksforGeeks Student Chapter",
  },
  {
    id: 6,
    url: sandeepjain6,
    caption: "Sandeep Jain, inspiring the next generation of tech enthusiasts at the seminar.",
  },
];

export default slides;
