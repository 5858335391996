import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "./Slider.css";
import "swiper/css";
import "swiper/css/navigation";
import slides from "./sliderData";

import {
  faChevronLeft,
  faChevronRight,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";

function Slider() {
  const swiperRef = useRef(null);
  const [fadeClass, setFadeClass] = useState("caption");
  const [realIndex, setRealIndex] = useState(0);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!swiperRef.current) return;
      const swiper = swiperRef.current.swiper;
      if (event.key === "ArrowLeft") {
        swiper.slidePrev();
      } else if (event.key === "ArrowRight") {
        swiper.slideNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleSlideChange = (swiper) => {
    setFadeClass("fade-out");
    setTimeout(() => {
      setRealIndex(swiper.realIndex);
      setFadeClass("caption");
    }, 500);
  };

  return (
    <div className="max-w-10/12 h-[780px] w-full m-auto py-16 px-4 relative group mt-44 rounded-2xl overflow-hidden">
      <Swiper
        ref={swiperRef}
        modules={[Navigation, Autoplay]}
        spaceBetween={30}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        loop={true}
        autoplay={{ delay: 5000 }}
        className="h-full rounded-2xl overflow-hidden"
        onSlideChange={handleSlideChange}
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            key={index}
            className="relative h-full rounded-2xl overflow-hidden"
          >
            <div
              style={{ backgroundImage: `url(${slide.url})` }}
              className="w-full h-full bg-center bg-cover rounded-2xl"
            >
              <div
                className={`absolute w-1/2 mx-auto bottom-16 left-0 right-0 text-center text-white bg-black bg-opacity-50 p-2 rounded ${fadeClass} text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl`}
              >
                {slide.caption}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Custom Navigation Arrows */}
      <div className="absolute top-[50%] left-5 z-10 group-hover:block text-md rounded-full p-2 cursor-pointer transform transition-transform duration-500 ease-in-out scale-0 group-hover:scale-100">
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="swiper-button-prev text-white"
          size="2x"
          onClick={() => swiperRef.current.swiper.slidePrev()}
        />
      </div>
      <div className="absolute top-[50%] right-5 z-10 group-hover:block text-md rounded-full p-2 cursor-pointer transform transition-transform duration-500 ease-in-out scale-0 group-hover:scale-100">
        <FontAwesomeIcon
          icon={faChevronRight}
          className="swiper-button-next text-white"
          size="2x"
          onClick={() => swiperRef.current.swiper.slideNext()}
        />
      </div>

      {/* Dots for Navigation */}
      <div className="flex top-4 justify-center py-2 text-black">
        {slides.map((_, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => {
              swiperRef.current.swiper.slideToLoop(slideIndex);
            }}
            className={`text-xs cursor-pointer ${
              slideIndex === realIndex ? "text-green-600" : "text-gray-200"
            } mx-1`}
          >
            <FontAwesomeIcon icon={faCircle} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Slider;
